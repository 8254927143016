import React from "react";
import OSPUNCPBA from "../../img/OSPUNCPBA.svg";
const MainLogo = () => (
    <div className="main-logo-container" >
        <img src={OSPUNCPBA} className="ospuncpba-main-logo-responsive" />
        <p className="ospuncpba-main-description">
          OBRA SOCIAL PERSONAL DE LA UNIVERSIDAD NACIONAL DEL CENTRO DE LA
          PROVINCIA DE BUENOS AIRES
        </p>
      </div>
    )
export default MainLogo;