import firebase from "firebase";

const config = {
  apiKey: "AIzaSyAAYxo435C-sr_DUIkvz2SOS82Ps1LC1AU",
  authDomain: "ospuncpba.firebaseapp.com",
  databaseURL: "https://ospuncpba-default-rtdb.firebaseio.com",
  projectId: "ospuncpba",
  storageBucket: "ospuncpba.appspot.com",
  messagingSenderId: "638849706341",
  appId: "1:638849706341:web:fac9cf25b6c78f6e40d98c",
  measurementId: "G-NZWXQ92DPE",
};

firebase.initializeApp(config);
firebase.analytics();

export const db = firebase.database();
