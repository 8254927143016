import { useLocation, useHistory } from "react-router-dom";
import "../App.css";
import { useEffect, useState } from "react";
import ls from "local-storage";
import { db } from "../services/firebase";
import moment from "moment";
import MainLogo from "../component/generic/MainLogo";
import FooterLogos from "../component/generic/FooterLogos";
import OSPUNCPBAORANGE from "../img/OSPUNCPBA-orange.svg";
import EXACTASLOGO from "../img/credencial-exactas-blanco.png";
import REDCOSUN from "../img/red_cosun.png";

import axios from "axios";

export default function AffiliateCardShared() {
  let location = useLocation();
  const history = useHistory();
  const [data, setData] = useState();
  const idType = location.state.idType;
  const idNumber = location.state.idNumber;
  const affiCode = location.state.affiCode;
  const timeLeft = location.state.timeLeft;
  let strLength = affiCode.length;
  let afi_cod = affiCode.substring(0, strLength - 2).trim();
  let codeSliced = affiCode.substring(strLength - 1);

  useEffect(async () => {
    const result = await axios(
      `https://ospuncpba-middleware-api.vercel.app/api?numero_afiliado=${affiCode}`
    );
    setData(result.data);
  }, []);

  useEffect(() => {
    let token = ls.get("token");
    let getAffiCode = ls.get("affiCode");
    
    db.ref(`/shares/${getAffiCode}/${token}`).once("value", (snapshot) => {
      let results = snapshot.val();

      if (results !== null) {
        showCredential(results);
      }
    });
    
  });


  const showCredential = (data) => {
    const DATE_TIME_FORMAT = "DD-MM-YYYY, h:mm a";
    let timeLeft = moment(data.sharedDateExpiration, DATE_TIME_FORMAT) - moment.now();
    if (data.status && data.status !== "sharedAction" || timeLeft < 0) {
      history.push("/expired");
    }
  }

  return (
    <div className="body-style">
      <MainLogo />
      {!data ? (
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
      ) : (
        <div className="card-container">
          <div className="credential-card">
            <p className="credential-title">Credencial compartida con</p>
            <div className="credential-container-subtitles">
              <div>
                <span className="credential-subtitle">{idType} </span>
                <span className="credential-subtitle">{idNumber}</span>
              </div>
              <div>
                <span className="credential-subtitle">Vence el </span>
                <span className="credential-subtitle">{timeLeft}</span>
              </div>
            </div>

            <div className="credential-card-container">
              <div className="credential-card-affiliate-container">
                <div>
                  <p className="credential-card-cod-affiliate">
                    1-{afi_cod}/{codeSliced}
                  </p>

                  <p className="credential-card-name-affiliate">
                    {data.afi_apellidonombres}
                  </p>
                  <span className="separator" />
                </div>
                <div className="ospuncpba-orange-container ">
                  <img src={OSPUNCPBAORANGE} width="160" />
                  <span className="ospuncpba-orange-text">
                    OBRA SOCIAL PERSONAL DE LA UNIVERSIDAD NACIONAL DEL CENTRO
                    DE LA PROVINCIA DE BUENOS AIRES
                  </span>
                </div>
                <div className="exactas-logo-red-cosun-container">
                  <img src={EXACTASLOGO} width="50" height="50" />
                  <div className="red-cosun-container">
                    <img src={REDCOSUN} width="50" height="17" />
                    <span>Red C.O.S.U.N.</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="credential-card-footer-container">
              <span className="credential-card-footer-text">
                Deberás acreditar la identidad del afiliado y presentarla junto
                con tu documento de identidad.
              </span>
            </div>
          </div>
        </div>
      )}

      <FooterLogos />
    </div>
  );
}
