import React from "react";
import REDCOSUN from "../../img/red_cosun.png";
import UNILOGO from "../../img/UNI_logo_blanco.png";

const FooterLogos = () => (
  <div className="footer-icons-style">
    <img src={UNILOGO} className="uni-logo" />
    <div className="red-cosun">
      <img src={REDCOSUN} className="red-cosun-logo" />
      <span>Red C.O.S.U.N.</span>
    </div>
  </div>
);
export default FooterLogos;
