import WARNING from "../img/warning.svg";

import MainLogo from "../component/generic/MainLogo";
import FooterLogos from "../component/generic/FooterLogos";
import "../App.css";

export default function AccessFromWeb() {
  return (
    <div className="body-style">
      <MainLogo />
      <div className="card-container">
        <div className="card expired-container ">
          <img src={WARNING} className="warn-logo" />
          <p className="access-not-allow-title">Acceso Denegado</p>
          <p className="access-not-allow-description">
            Por favor entra a este mismo link desde tu celular para completar el
            registro
          </p>
        </div>
      </div>
      <FooterLogos />
    </div>
  );
}
