import { Suspense } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.css";
import CardExpired from "./component/CardExpired";
import AffiliateCardShared from "./component/AffiliateCardShared";
import AccessFromWeb from "./component/AccessFromWeb";
import Home from "./component/Home";

function App() {
  return (
        <Router>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/affiliateCard">
              <AffiliateCardShared />
            </Route>
            <Route exact path="/expired">
              <CardExpired />
            </Route>
            <Route exact path="/accessFromWeb">
              <AccessFromWeb />
            </Route>
          </Switch>
        </Router>

  );
}

export default App;
