import { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ls from "local-storage";
import { db } from "../services/firebase";
import moment from "moment";
import FooterLogos from "../component/generic/FooterLogos";
import MainLogo from "../component/generic/MainLogo";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function Home() {
  let query = useQuery();
  const history = useHistory();
  const [idType, setIdType] = useState("DNI");
  const [idNumber, setIdNumber] = useState("");
  const [affiCode, setAffiCode] = useState("");
  const [token, setToken] = useState("");
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    let dataEncrypt = query.get("token");
    ls.set('token', dataEncrypt)
    db.ref(`/bindings/${dataEncrypt}`).once("value", (snapshot) => {
      let results = snapshot.val();

      if (results !== null) {
        ls.set('affiCode', results.affiCode)
        setAffiCode(results.affiCode);
      }
    });
    setToken(dataEncrypt);
  }, []);

  const getCredentialData = () => {
    db.ref(`/shares/${affiCode}/${token}`).once("value", (snapshot) => {
      let results = snapshot.val();

      if (results !== null) {
        showCredential(results);
      }
    });
  };

  const showCredential = (data) => {
    const DATE_TIME_FORMAT = "DD-MM-YYYY, h:mm a";
    let timeLeft = moment(data.sharedDateExpiration, DATE_TIME_FORMAT) - moment.now();
    
    if (idNumber === data.idNumber && idType === data.idType) {
      if (data.status && data.status === "sharedAction" && timeLeft > 0) {
        setShowError(false);
        history.push("/affiliateCard", {
          idNumber: idNumber,
          idType: idType,
          affiCode: affiCode,
          timeLeft: data.sharedDateExpiration,
        });
      } else {
        setShowError(false);
        history.push("/expired");
      }
    } else {
      setShowError(true);
    }
  };

  const handleInputChange = (event) => {
    const number = event.target.value;
    setIdNumber(number);
  };

  const handleOptionChange = (event) => {
    const type = event.target.value;
    setIdType(type);
  };

  const handleCancel = () => {
    setIdNumber("");
    setShowError(false);
  };
  const handleNext = async () => {
    await getCredentialData();
  };

  return (
    <div className="body-style">
      <MainLogo />
      <div className="card-container">
        <div className="card">
          <h4 className="title">Ingresa Tipo y Número de Documento.</h4>
          <select className="select-style" onChange={handleOptionChange}>
            <option className="select-text" value="DNI">
              DNI
            </option>
            <option className="select-text" value="LC">
              LC
            </option>
            <option className="select-text" value="LE">
              LE
            </option>
            <option className="select-text" value="CUIL">
              CUIL
            </option>
            <option className="select-text" value="PASAPORTE">
              PASAPORTE
            </option>
          </select>
          <input
            className="input-style"
            placeholder="Nº de documento"
            type="text"
            value={idNumber}
            onChange={handleInputChange}
            style={{ borderColor: showError ? "red" : "#dddddd" }}
            maxLength="12"
          />
          {showError && (
            <span className="error-message">
              El tipo ó número de documento ingresado es incorrecto, por favor,
              volvé a intentarlo.
            </span>
          )}
          <div className="buttons-container">
            <button className="button-cancel" onClick={() => handleCancel()}>
              Cancelar
            </button>
            <button
              className={
                idNumber.length >= 4 ? "button-confirm" : "button-disabled"
              }
              onClick={() => handleNext()}
              disabled={idNumber.length < 4}
            >
              Confirmar
            </button>
          </div>
        </div>
      </div>

      <FooterLogos />
    </div>
  );
}
