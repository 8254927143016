import WARNING from "../img/warning.svg";

import MainLogo from '../component/generic/MainLogo'
import FooterLogos from '../component/generic/FooterLogos'
import "../App.css";

export default function CardExpired() {
  return (
    <div className="body-style">
      <MainLogo />
      <div className="card-container">
        <div className="card expired-container ">
          <img src={WARNING} className="warn-logo" />
          <p className="expired-text-style">
          La credencial compartida ha expirado. Solicita al titular un nuevo permiso de acceso.
          </p>
        </div>
      </div>
      <FooterLogos />
    </div>
  );
}
